// import UUIDs for ID Management
import { v4 as uuid } from 'uuid';

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    myFiles: [
        {
            id: 'folder1',
            title: 'Folder Holding Items',
            itemType: 'myFilesFolder',
            shared: [1, 2, 3],
            url: null,
            children: [
                {
                    id: 'campaign1',
                    title: 'Campaign 1',
                    itemType: 'myFilesCampaign',
                    shared: [],
                    url: '/campaign-page'
                },
                {
                    id: 'list1',
                    title: 'List 1',
                    itemType: 'myFilesList',
                    shared: [],
                    url: '/list-page',
                    turfOptions: [
                        {
                            id: 'assignment1',
                            title: 'Assignment 1'
                        },
                        {
                            id: 'assignment2',
                            title: 'Assignment 2'
                        }
                    ]
                },
                {
                    id: 'survey1',
                    title: 'Survey 1',
                    itemType: 'myFilesSurvey',
                    shared: [],
                    url: '/survey-page'
                },
                {
                    id: 'report1',
                    title: 'Report 1',
                    itemType: 'myFilesReport',
                    shared: [],
                    url: '/'
                }
            ]
        },
        {
            id: 'campaign2',
            title: 'Campaign 2',
            itemType: 'myFilesCampaign',
            shared: [],
            url: '/campaign-page'
        },
        {
            id: 'list2',
            title: 'List 2',
            itemType: 'myFilesList',
            shared: [],
            url: '/list-page'
        },
        {
            id: 'survey2',
            title: 'Survey 2',
            itemType: 'myFilesSurvey',
            shared: [],
            url: '/survey-page'
        },
        {
            id: 'report2',
            title: 'Report 2',
            itemType: 'myFilesReport',
            shared: [],
            url: '/'
        }
    ],
    createNew: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            itemType: 'createNewDashboard',
            url: '/dashboard',
            selected: true,
            active: true
        },
        {
            id: 'navMainCreateNewCollapse',
            title: 'Create New',
            itemType: 'createNew',
            children: [
                {
                    id: 'navMainCreateNewFolder',
                    title: 'Folder',
                    itemType: 'createNewFolder'
                },
                {
                    id: 'createNewCampaign',
                    title: 'Campaign',
                    itemType: 'createNewCampaign',
                    url: '/create-new-campaign'
                },
                {
                    id: 'createNewList',
                    title: 'List',
                    itemType: 'createNewList',
                    url: '/create-new-list'
                },
                {
                    id: 'createNewSurvey',
                    title: 'Survey',
                    itemType: 'createNewSurvey',
                    url: '/create-new-survey'
                }
            ]
        }
    ]
};

// ===========================|| MENU ITEMS REDUCER ||=========================== //

const menuItemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_FOLDER:
            return {
                ...state,
                myFiles: [
                    ...state.myFiles,
                    {
                        id: uuid(),
                        title: action.payload,
                        itemType: 'folder',
                        url: null,
                        shared: [],
                        children: []
                    }
                ]
            };
        case actionTypes.RENAME_FILE: {
            const { id, newName } = action.payload;
            const newArr = state.myFiles.map((item) => {
                if (item.id === id) {
                    item.title = newName;
                } else if (item.children) {
                    item.children.map((child) => {
                        if (child.id === id) {
                            child.title = newName;
                        }
                        return child;
                    });
                }
                return item;
            });
            return {
                ...state,
                myFiles: newArr
            };
        }
        case actionTypes.SET_SHARED_USERS: {
            const { id, users } = action.payload;
            const newArr = state.myFiles.map((item) => {
                if (item.id === id) {
                    item.shared = users;
                    if (item.children) {
                        item.children.map((child) => {
                            child.shared = users;
                            return child;
                        });
                    }
                } else if (item.children) {
                    item.children.map((child) => {
                        if (child.id === id) {
                            child.shared = users;
                        }
                        return child;
                    });
                }
                return item;
            });
            return {
                ...state,
                myFiles: newArr
            };
        }
        case actionTypes.SET_FOLDER_ITEMS: {
            const { id, fileOptions } = action.payload;
            const fileOptionsIDs = fileOptions.map((item) => item.id);
            const removedItemsArr = state.myFiles.flatMap((item) => {
                if (item.id === id) {
                    const removedItems = item.children.flatMap((child) => {
                        if (fileOptionsIDs.includes(child.id)) {
                            return [];
                        }
                        return child;
                    });
                    return removedItems;
                }
                return [];
            });
            const newArr = state.myFiles.flatMap((item) => {
                if (fileOptionsIDs.includes(item.id)) {
                    return [];
                }
                if (item.id === id) {
                    item.children = fileOptions;
                    return item;
                }
                return item;
            });
            newArr.push(...removedItemsArr);
            return {
                ...state,
                myFiles: newArr
            };
        }
        case actionTypes.SET_SELECTED_STATE: {
            const { id } = action.payload;
            const newMyFilesArr = state.myFiles.map((item) => {
                if (item.id === id) {
                    item.selected = true;
                } else if (item.id !== id) {
                    item.selected = false;
                }
                if (item.children) {
                    const newChildArr = item.children.map((child) => {
                        if (child.id === id) {
                            child.selected = true;
                        } else if (child.id !== id) {
                            child.selected = false;
                        }
                        return child;
                    });
                    item.children = newChildArr;
                }
                return item;
            });
            const newCreateNewArr = state.createNew.map((item) => {
                if (item.id === id) {
                    item.selected = true;
                } else if (item.id !== id) {
                    item.selected = false;
                }
                if (item.children) {
                    const newChildArr = item.children.map((child) => {
                        if (child.id === id) {
                            child.selected = true;
                        } else if (child.id !== id) {
                            child.selected = false;
                        }
                        return child;
                    });
                    item.children = newChildArr;
                }
                return item;
            });
            return {
                ...state,
                myFiles: newMyFilesArr,
                createNew: newCreateNewArr
            };
        }
        case actionTypes.SET_ACTIVE_STATE: {
            const { id } = action.payload;
            const newMyFilesArr = state.myFiles.map((item) => {
                if (item.id === id) {
                    item.active = true;
                }
                if (item.children) {
                    const newChildArr = item.children.map((child) => {
                        if (child.id === id) {
                            child.active = true;
                        }
                        return child;
                    });
                    item.children = newChildArr;
                }
                return item;
            });
            const newCreateNewArr = state.createNew.map((item) => {
                if (item.id === id) {
                    item.active = true;
                }
                if (item.children) {
                    const newChildArr = item.children.map((child) => {
                        if (child.id === id) {
                            child.active = true;
                        }
                        return child;
                    });
                    item.children = newChildArr;
                }
                return item;
            });
            return {
                ...state,
                myFiles: newMyFilesArr,
                createNew: newCreateNewArr
            };
        }
        case actionTypes.REMOVE_ACTIVE_STATE: {
            const { id } = action.payload;
            const newMyFilesArr = state.myFiles.map((item) => {
                if (item.id === id) {
                    item.active = false;
                }
                if (item.children) {
                    const newChildArr = item.children.map((child) => {
                        if (child.id === id) {
                            child.active = false;
                        }
                        return child;
                    });
                    item.children = newChildArr;
                }
                return item;
            });
            const newCreateNewArr = state.createNew.map((item) => {
                if (item.id === id) {
                    item.active = false;
                }
                if (item.children) {
                    const newChildArr = item.children.map((child) => {
                        if (child.id === id) {
                            child.active = false;
                        }
                        return child;
                    });
                    item.children = newChildArr;
                }
                return item;
            });
            return {
                ...state,
                myFiles: newMyFilesArr,
                createNew: newCreateNewArr
            };
        }
        default:
            return state;
    }
};

export default menuItemsReducer;
