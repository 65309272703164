import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { makeStyles, withStyles } from '@material-ui/styles';
import { Avatar, Chip, ListItemIcon, ListItemText, Typography, IconButton, Button, Dialog, Grid, TextField } from '@material-ui/core';
import ListItemButton from '@material-ui/core/ListItemButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

// project imports
import { gridSpacing } from 'store/constant';
import { ADD_FOLDER } from 'store/actions';

// assets
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CloseIcon from '@material-ui/icons/Close';

// style constant
const useStyles = makeStyles((theme) => ({
    listIcon: {
        minWidth: '18px',
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    listCustomIconSub: {
        width: '6px',
        height: '6px'
    },
    listCustomIconSubActive: {
        width: '8px',
        height: '8px'
    },
    listItem: {
        marginBottom: '5px',
        alignItems: 'center'
    },
    listItemNoBack: {
        marginBottom: '5px',
        backgroundColor: 'transparent !important',
        paddingTop: '8px',
        paddingBottom: '8px',
        alignItems: 'flex-start'
    },
    subMenuCaption: {
        ...theme.typography.subMenuCaption
    }
}));

// style constant
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2.5)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.grey[500]
    }
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(3)
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    }
}))(MuiDialogActions);

// =============================|| DIALOG TITLE ||============================= //

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle className={classes.root} {...other}>
            {children}
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

// ===========================|| Create Folder Item ||=========================== //

const NavCreateNewFolder = ({ item, level }) => {
    const classes = useStyles();
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();

    const Icon = item.icon;
    const itemIcon = item.icon ? (
        <Icon stroke={1.5} size="1.3rem" className={classes.listCustomIcon} />
    ) : (
        <FiberManualRecordIcon
            className={
                customization.isOpen.findIndex((id) => id === item.id) > -1 ? classes.listCustomIconSubActive : classes.listCustomIconSub
            }
            fontSize={level > 0 ? 'inherit' : 'default'}
        />
    );

    let itemIconClass = !item.icon ? classes.listIcon : classes.menuIcon;
    itemIconClass = customization.navType === 'nav-dark' ? [itemIconClass, classes.listCustomIcon].join(' ') : itemIconClass;

    let itemTarget = '';
    if (item.target) {
        itemTarget = '_blank';
    }

    // Setting Up Vars & Funcs for Dialog Box
    const [folderName, setFolderName] = useState('');
    const handleFolderNameChange = (e) => {
        setFolderName(e.target.value);
    };
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleSave = () => {
        dispatch({ type: ADD_FOLDER, payload: folderName });
        handleClose();
    };

    return (
        <>
            <ListItemButton
                disabled={item.disabled}
                className={level > 1 ? classes.listItemNoBack : classes.listItem}
                sx={{ borderRadius: `${customization.borderRadius}px` }}
                selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
                onClick={() => handleOpen()}
                target={itemTarget}
                style={{ paddingLeft: `${level * 23}px` }}
            >
                <ListItemIcon className={itemIconClass}>{itemIcon}</ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} color="inherit">
                            {item.title}
                        </Typography>
                    }
                    secondary={
                        item.caption && (
                            <Typography variant="caption" className={classes.subMenuCaption} display="block" gutterBottom>
                                {item.caption}
                            </Typography>
                        )
                    }
                />
                {item.chip && (
                    <Chip
                        color={item.chip.color}
                        variant={item.chip.variant}
                        size={item.chip.size}
                        label={item.chip.label}
                        avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                    />
                )}
            </ListItemButton>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} sx={{ '& .MuiDialog-paper': { pr: 0 } }}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ minWidth: '500px' }}>
                    Create New Folder
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={gridSpacing}>
                        <Grid item sm={12} xs={12} md={12} lg={12}>
                            <TextField fullWidth placeholder="Enter Folder Name" value={folderName} onChange={handleFolderNameChange} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant="contained" size="small" onClick={handleSave}>
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

NavCreateNewFolder.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavCreateNewFolder;
