// import UUIDs for ID Management
import { v4 as uuid } from 'uuid';

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    // eslint-disable-next-line prettier/prettier
    'Main': [
        {
            id: uuid(),
            type: 'greetingText'
        },
        {
            id: uuid(),
            type: 'standardText'
        },
        {
            id: uuid(),
            type: 'question',
            commentSection: true,
            answers: [{ id: uuid(), answerText: '' }],
            attachedPath: true
        },
        {
            id: uuid(),
            type: 'path',
            answerOptions: [
                { id: uuid(), answerText: 'Sample 5' },
                { id: uuid(), answerText: 'Sample 6' }
            ],
            pathStaging: [
                {
                    id: uuid(),
                    answers: [
                        { id: uuid(), answerText: 'Sample 12' },
                        { id: uuid(), answerText: 'Sample 1' }
                    ],
                    name: '',
                    startItem: 'startText'
                }
            ],
            paths: [
                {
                    id: '1',
                    answers: [
                        { id: uuid(), answerText: 'Sample 1' },
                        { id: uuid(), answerText: 'Sample 2' }
                    ],
                    name: 'Path 1',
                    startItem: 'startText'
                },
                {
                    id: '2',
                    answers: [
                        { id: uuid(), answerText: 'Sample 3' },
                        { id: uuid(), answerText: 'Sample 4' }
                    ],
                    name: 'Path 2',
                    startItem: 'startQuestion'
                }
            ],
            questionID: ''
        }
    ],
    'Path 1': [
        {
            id: '1',
            type: 'startText'
        }
    ],
    'Path 2': [
        {
            id: '2',
            type: 'startQuestion',
            answers: [{ id: uuid(), answerText: '' }]
        }
    ]
};

// ===========================|| CAMPAIGN CREATOR REDUCER ||=========================== //

const surveyCreationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REMOVE_PATH: {
            const { paths } = action.payload;
            const pathNames = paths.map((path) => path.name);
            const newState = { ...state };
            for (let i = 0; i < pathNames.length; i += 1) {
                delete newState[pathNames[i]];
            }
            return newState;
        }
        case actionTypes.SET_PATH_BLOCK_PATH_GENERATION: {
            const { blockID, node, stagedPaths } = action.payload;
            let newPaths = {};
            for (let i = 0; i < stagedPaths.length; i += 1) {
                newPaths = {
                    ...newPaths,
                    [stagedPaths[i].name]: [
                        {
                            id: stagedPaths[i].id,
                            type: stagedPaths[i].startItem
                        }
                    ]
                };
                if (newPaths[stagedPaths[i].name][0].type === 'startQuestion') {
                    newPaths[stagedPaths[i].name][0].answers = [{ id: uuid(), answerText: '' }];
                    newPaths[stagedPaths[i].name][0].commentSection = true;
                }
            }
            return {
                ...state,
                ...newPaths,
                [node]: state[node].map((block) => {
                    if (block.id === blockID) {
                        return {
                            ...block,
                            paths: stagedPaths,
                            pathGenerated: true
                        };
                    }
                    return block;
                })
            };
        }
        case actionTypes.ADD_PATH_BLOCK: {
            const { questionID, node, answerOptions } = action.payload;
            const newBlock = {
                id: uuid(),
                type: 'path',
                pathStaging: [
                    {
                        id: uuid(),
                        answers: [],
                        name: '',
                        startItem: ''
                    }
                ],
                answerOptions,
                questionID
            };
            const newArr = [...state[node], newBlock];
            return {
                ...state,
                [node]: newArr
            };
        }
        case actionTypes.SET_PATH_BLOCK_PATH_ASSIGNMENT_ANSWER_SELECTION: {
            const { blockID, node, pathID, value } = action.payload;
            return {
                ...state,
                [node]: state[node].map((block) => {
                    if (block.id === blockID) {
                        return {
                            ...block,
                            pathStaging: block.pathStaging.map((path) => {
                                if (path.id === pathID) {
                                    return {
                                        ...path,
                                        answers: value
                                    };
                                }
                                return path;
                            })
                        };
                    }
                    return block;
                })
            };
        }
        case actionTypes.SET_PATH_BLOCK_PATH_ASSIGNMENT_NAME: {
            const { blockID, node, pathID, value } = action.payload;
            return {
                ...state,
                [node]: state[node].map((block) => {
                    if (block.id === blockID) {
                        return {
                            ...block,
                            pathStaging: block.pathStaging.map((path) => {
                                if (path.id === pathID) {
                                    return {
                                        ...path,
                                        name: value
                                    };
                                }
                                return path;
                            })
                        };
                    }
                    return block;
                })
            };
        }
        case actionTypes.SET_PATH_BLOCK_PATH_ASSIGNMENT_START_ITEM: {
            const { blockID, node, pathID, value } = action.payload;
            return {
                ...state,
                [node]: state[node].map((block) => {
                    if (block.id === blockID) {
                        return {
                            ...block,
                            pathStaging: block.pathStaging.map((path) => {
                                if (path.id === pathID) {
                                    return {
                                        ...path,
                                        startItem: value
                                    };
                                }
                                return path;
                            })
                        };
                    }
                    return block;
                })
            };
        }
        case actionTypes.REMOVE_PATH_BLOCK_PATH_ASSIGNMENT: {
            const { id, node, assignmentID } = action.payload;
            return {
                ...state,
                [node]: state[node].map((block) => {
                    if (block.id === id) {
                        return {
                            ...block,
                            pathStaging: block.pathStaging.filter((assignment) => assignment.id !== assignmentID)
                        };
                    }
                    return block;
                })
            };
        }
        case actionTypes.ADD_PATH_BLOCK_PATH_ASSIGNMENT: {
            const { id, node } = action.payload;
            const newPathAssignment = {
                id: uuid()
            };
            return {
                ...state,
                [node]: state[node].map((block) => {
                    if (block.id === id) {
                        return {
                            ...block,
                            pathStaging: [...block.pathStaging, newPathAssignment]
                        };
                    }
                    return block;
                })
            };
        }
        case actionTypes.SET_QUESTION_BLOCK_ENABLED: {
            const { questionID, node } = action.payload;
            return {
                ...state,
                [node]: state[node].map((block) => {
                    if (block.id === questionID) {
                        return {
                            ...block,
                            attachedPath: false
                        };
                    }
                    return block;
                })
            };
        }
        case actionTypes.SET_QUESTION_BLOCK_DISABLED: {
            const { questionID, node } = action.payload;
            return {
                ...state,
                [node]: state[node].map((block) => {
                    if (block.id === questionID) {
                        return {
                            ...block,
                            attachedPath: true
                        };
                    }
                    return block;
                })
            };
        }
        case actionTypes.REMOVE_QUESTION_BLOCK_ANSWER: {
            const { questionID, node, answerID } = action.payload;
            return {
                ...state,
                [node]: state[node].map((block) => {
                    if (block.id === questionID) {
                        return {
                            ...block,
                            answers: block.answers.filter((answer) => answer.id !== answerID)
                        };
                    }
                    return block;
                })
            };
        }
        case actionTypes.ADD_QUESTION_BLOCK_ANSWER: {
            const { questionID, node } = action.payload;
            const newAnswer = {
                id: uuid(),
                answerText: ''
            };
            return {
                ...state,
                [node]: state[node].map((block) => {
                    if (block.id === questionID) {
                        return {
                            ...block,
                            answers: [...block.answers, newAnswer]
                        };
                    }
                    return block;
                })
            };
        }
        case actionTypes.SET_QUESTION_BLOCK_COMMENT_SWITCH: {
            const { id, node, value } = action.payload;
            return {
                ...state,
                [node]: state[node].map((block) => {
                    if (block.id === id) {
                        return {
                            ...block,
                            commentSection: value
                        };
                    }
                    return block;
                })
            };
        }
        case actionTypes.SET_QUESTION_BLOCK_ANSWER_TEXT: {
            const { questionID, node, answerID, text } = action.payload;
            return {
                ...state,
                [node]: state[node].map((block) => {
                    if (block.id === questionID) {
                        return {
                            ...block,
                            answers: block.answers.map((answer) => {
                                if (answer.id === answerID) {
                                    return {
                                        ...answer,
                                        answerText: text
                                    };
                                }
                                return answer;
                            })
                        };
                    }
                    return block;
                })
            };
        }
        case actionTypes.SET_QUESTION_BLOCK_QUESTION_TEXT: {
            const { id, node, text } = action.payload;
            return {
                ...state,
                [node]: state[node].map((block) => {
                    if (block.id === id) {
                        return {
                            ...block,
                            question: text
                        };
                    }
                    return block;
                })
            };
        }
        case actionTypes.SET_START_TEXT_BLOCK_TEXT: {
            const { id, node, text } = action.payload;
            return {
                ...state,
                [node]: state[node].map((block) => {
                    if (block.id === id) {
                        return {
                            ...block,
                            text
                        };
                    }
                    return block;
                })
            };
        }
        case actionTypes.REMOVE_STANDARD_BLOCK: {
            const { id, node } = action.payload;
            return {
                ...state,
                [node]: state[node].filter((item) => item.id !== id)
            };
        }
        case actionTypes.SET_STANDARD_TEXT_BLOCK_TEXT: {
            const { id, node, text } = action.payload;
            return {
                ...state,
                [node]: state[node].map((block) => {
                    if (block.id === id) {
                        return {
                            ...block,
                            text
                        };
                    }
                    return block;
                })
            };
        }
        case actionTypes.ADD_QUESTION_BLOCK: {
            const { id, node } = action.payload;
            const index = state[node].findIndex((item) => item.id === id);
            const newBlock = {
                id: uuid(),
                type: 'question',
                commentSection: true,
                answers: [{ id: uuid(), text: '' }]
            };
            const newArr = [...state[node].slice(0, index + 1), newBlock, ...state[node].slice(index + 1)];
            return {
                ...state,
                [node]: newArr
            };
        }
        case actionTypes.ADD_STANDARD_TEXT_BLOCK: {
            const { id, node } = action.payload;
            const index = state[node].findIndex((item) => item.id === id);
            const newBlock = {
                id: uuid(),
                type: 'standardText'
            };
            const newArr = [...state[node].slice(0, index + 1), newBlock, ...state[node].slice(index + 1)];
            return {
                ...state,
                [node]: newArr
            };
        }
        case actionTypes.SET_GREETING_BLOCK_TEXT: {
            const { id, node, text } = action.payload;
            return {
                ...state,
                [node]: state[node].map((block) => {
                    if (block.id === id) {
                        return {
                            ...block,
                            text
                        };
                    }
                    return block;
                })
            };
        }
        default:
            return state;
    }
};

export default surveyCreationReducer;
