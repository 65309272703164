import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconMessage, IconPhone, IconWalk } from '@tabler/icons';

// constant
const icons = { IconMessage, IconPhone, IconWalk };

// ===========================|| UI FORMS MENU ITEMS ||=========================== //

const volunteerFiles = {
    id: 'ui-volunteerFiles',
    title: <FormattedMessage id="Volunteer Files" />,
    type: 'group',
    children: [
        {
            id: 'textBank1',
            title: <FormattedMessage id="Text Bank 1" />,
            type: 'item',
            url: '/volunteer/text-bank',
            icon: icons.IconMessage,
            breadcrumbs: false
        },
        {
            id: 'phoneBank1',
            title: <FormattedMessage id="Phone Bank 1" />,
            type: 'item',
            url: '/volunteer/phone-bank',
            icon: icons.IconPhone,
            breadcrumbs: false
        },
        {
            id: 'canvas1',
            title: <FormattedMessage id="Canvas 1" />,
            type: 'item',
            url: '/volunteer/canvas',
            icon: icons.IconWalk,
            breadcrumbs: false
        }
    ]
};

export default volunteerFiles;
