import React from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import BreadcrumbNavMyFilesCampaign from './BreadcrumbNavMyFilesCampaign';
import BreadcrumbNavCreateNewDashboard from './BreadcrumbNavCreateNewDashboard';
import BreadcrumbNavCreateNewCampaign from './BreadcrumbNavCreateNewCampaign';
import BreadcrumbNavCreateNewList from './BreadcrumbNavCreateNewList';
import BreadcrumbNavCreateNewReport from './BreadcrumbNavCreateNewReport';
import BreadcrumbNavCreateNewSurvey from './BreadcrumbNavCreateNewSurvey';
import BreadcrumbNavMyFilesList from './BreadcrumbNavMyFilesList';
import BreadcrumbNavMyFilesReport from './BreadcrumbNavMyFilesReport';
import BreadcrumbNavMyFilesSurvey from './BreadcrumbNavMyFilesSurvey';

// styles
const useStyles = makeStyles(() => ({
    iconNav: {
        height: '16px'
    },
    mainCard: {
        padding: '0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
}));

// ================================|| BREADCRUMB NAVIGATION ||================================ //

const BreadcrumbNav = () => {
    const classes = useStyles();
    // Load menu items from Redux & Create Array
    const createNew = useSelector((state) => state.menuItems.createNew);
    const myFiles = useSelector((state) => state.menuItems.myFiles);
    // Function to create an array of all active items.
    const filesArrayCleaner = (arr) => {
        const newArr = [];
        for (let i = 0; i < arr.length; i += 1) {
            if (arr[i].active) {
                newArr.push(arr[i]);
            } else if (arr[i].children) {
                for (let j = 0; j < arr[i].children.length; j += 1) {
                    if (arr[i].children[j].active) {
                        newArr.push(arr[i].children[j]);
                    }
                }
            }
        }
        return newArr;
    };
    // Create arrays with all active items.
    const createNewActive = filesArrayCleaner(createNew);
    const myFilesActive = filesArrayCleaner(myFiles);
    // Combine all active items.
    const allActiveItemsCombined = [...createNewActive, ...myFilesActive];

    const breadCrumbItems = allActiveItemsCombined.map((item, index, array) => {
        switch (item.itemType) {
            case 'createNewCampaign':
                return <BreadcrumbNavCreateNewCampaign key={item.id} item={item} breadcrumbIndex={index} breadcrumbArray={array} />;
            case 'createNewDashboard':
                return <BreadcrumbNavCreateNewDashboard key={item.id} item={item} breadcrumbIndex={index} breadcrumbArray={array} />;
            case 'createNewList':
                return <BreadcrumbNavCreateNewList key={item.id} item={item} breadcrumbIndex={index} breadcrumbArray={array} />;
            case 'createNewSurvey':
                return <BreadcrumbNavCreateNewSurvey key={item.id} item={item} breadcrumbIndex={index} breadcrumbArray={array} />;
            case 'createNewReport':
                return <BreadcrumbNavCreateNewReport key={item.id} item={item} breadcrumbIndex={index} breadcrumbArray={array} />;
            case 'myFilesCampaign':
                return <BreadcrumbNavMyFilesCampaign key={item.id} item={item} breadcrumbIndex={index} breadcrumbArray={array} />;
            case 'myFilesList':
                return <BreadcrumbNavMyFilesList key={item.id} item={item} breadcrumbIndex={index} breadcrumbArray={array} />;
            case 'myFilesSurvey':
                return <BreadcrumbNavMyFilesSurvey key={item.id} item={item} breadcrumbIndex={index} breadcrumbArray={array} />;
            case 'myFilesReport':
                return <BreadcrumbNavMyFilesReport key={item.id} item={item} breadcrumbIndex={index} breadcrumbArray={array} />;
            default:
                return <BreadcrumbNavMyFilesCampaign key={item.id} item={item} breadcrumbIndex={index} breadcrumbArray={array} />;
        }
    });

    return (
        <MainCard className={classes.mainCard}>
            <Grid container spacing={gridSpacing}>
                {breadCrumbItems}
            </Grid>
        </MainCard>
    );
};

export default BreadcrumbNav;
