import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { makeStyles, withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import {
    Avatar,
    Chip,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery,
    Tooltip,
    IconButton,
    Dialog,
    Grid,
    TextField,
    Button,
    Autocomplete
} from '@material-ui/core';
import ListItemButton from '@material-ui/core/ListItemButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

// project imports
import { MENU_OPEN, SET_MENU, RENAME_FILE, SET_SHARED_USERS, SET_SELECTED_STATE, SET_ACTIVE_STATE } from 'store/actions';
import { gridSpacing } from 'store/constant';

// assets
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { IconSettings } from '@tabler/icons';
import CloseIcon from '@material-ui/icons/Close';

// style constant
const useStyles = makeStyles((theme) => ({
    listIcon: {
        minWidth: '18px',
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    listCustomIconSub: {
        width: '6px',
        height: '6px'
    },
    listCustomIconSubActive: {
        width: '8px',
        height: '8px'
    },
    listItem: {
        marginBottom: '5px',
        alignItems: 'center'
    },
    listItemNoBack: {
        marginBottom: '5px',
        backgroundColor: 'transparent !important',
        paddingTop: '8px',
        paddingBottom: '8px',
        alignItems: 'flex-start'
    },
    subMenuCaption: {
        ...theme.typography.subMenuCaption
    }
}));

// style for dialog constant
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2.5)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.grey[500]
    }
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(3)
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    }
}))(MuiDialogActions);

// =============================|| DIALOG TITLE ||============================= //

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle className={classes.root} {...other}>
            {children}
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

// ===========================|| SIDEBAR LIST ITEMS ||=========================== //

const NavMyFilesItem = ({ item, level }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const users = useSelector((state) => state.users);
    const matchesSM = useMediaQuery(theme.breakpoints.down('md'));

    // show settings tooltip based on hover
    const [active, setActive] = useState(false);
    const showSettings = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setActive(true);
        console.log('Mouse has entered the item.');
    };
    const hideSettings = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setActive(false);
        console.log('Mouse has left the item.');
    };

    // Handles the opening and closing of the dialog.
    const [open, setOpen] = useState(false);
    const handleTooltipClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setActive(false);
    };

    // Handles Change of the File Name
    const [fileName, setFileName] = useState(item.title.props.id);
    const handleFileNameChange = (e) => {
        setFileName(e.target.value);
    };

    // Creates array of default users that were shared on the file to be used by State below.
    const defaultSharedUsers = item.shared.map((item) => {
        const findUserName = (element) => element.id === item;
        const index = users.findIndex(findUserName);
        return users[index];
    });

    // Handles Change of Shared Users
    const [sharedUsers, setSharedUsers] = useState(defaultSharedUsers);
    const handleSharedUserChange = (e, value) => {
        e.preventDefault();
        setSharedUsers(value);
    };

    // handle sharing and settings save for dialog
    const handleSharingSettingsSave = (e) => {
        e.preventDefault();
        dispatch({ type: RENAME_FILE, payload: { id: item.id, newName: fileName } });
        dispatch({ type: SET_SHARED_USERS, payload: { id: item.id, users: sharedUsers } });
        handleClose();
    };

    const Icon = item.icon;
    const itemIcon = item.icon ? (
        <Icon stroke={1.5} size="1.3rem" className={classes.listCustomIcon} />
    ) : (
        <FiberManualRecordIcon
            className={
                customization.isOpen.findIndex((id) => id === item.id) > -1 ? classes.listCustomIconSubActive : classes.listCustomIconSub
            }
            fontSize={level > 0 ? 'inherit' : 'default'}
        />
    );

    let itemIconClass = !item.icon ? classes.listIcon : classes.menuIcon;
    itemIconClass = customization.navType === 'nav-dark' ? [itemIconClass, classes.listCustomIcon].join(' ') : itemIconClass;

    let itemTarget = '';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = { component: React.forwardRef((props, ref) => <Link {...props} to={item.url} ref={ref} />) };
    if (item.external) {
        listItemProps = { component: 'a', href: item.url };
    }

    const itemHandler = (itemId) => {
        dispatch({ type: MENU_OPEN, id: itemId });
        dispatch({ type: SET_SELECTED_STATE, payload: { id: item.id } });
        dispatch({ type: SET_ACTIVE_STATE, payload: { id: item.id } });
        if (matchesSM) dispatch({ type: SET_MENU, opened: false });
        console.log('is this firing tooo?');
        console.log(itemId);
    };

    // active menu item on page load
    React.useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <ListItemButton
                {...listItemProps}
                disabled={item.disabled}
                className={level > 1 ? classes.listItemNoBack : classes.listItem}
                sx={{ borderRadius: `${customization.borderRadius}px` }}
                selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
                onClick={() => itemHandler(item.id)}
                target={itemTarget}
                style={{ paddingLeft: `${level * 23}px` }}
                onMouseEnter={(e) => showSettings(e)}
                onMouseLeave={(e) => hideSettings(e)}
            >
                <ListItemIcon className={itemIconClass}>{itemIcon}</ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} color="inherit">
                            {item.title}
                        </Typography>
                    }
                    secondary={
                        item.caption && (
                            <Typography variant="caption" className={classes.subMenuCaption} display="block" gutterBottom>
                                {item.caption}
                            </Typography>
                        )
                    }
                />
                {item.chip && (
                    <Chip
                        color={item.chip.color}
                        variant={item.chip.variant}
                        size={item.chip.size}
                        label={item.chip.label}
                        avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                    />
                )}
                {active ? (
                    <Tooltip title="Settings">
                        <IconButton color="primary" size="small" onClick={(e) => handleTooltipClick(e)}>
                            <IconSettings stroke={1.5} size="1.5rem" />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </ListItemButton>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} sx={{ '& .MuiDialog-paper': { pr: 0 } }}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ minWidth: '500px' }}>
                    Sharing and Settings
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={gridSpacing}>
                        <Grid item sm={12}>
                            <TextField fullWidth label="File Name" value={fileName} onChange={handleFileNameChange} />
                        </Grid>
                        <Grid item sm={12}>
                            <Autocomplete
                                multiple
                                options={users}
                                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                defaultValue={sharedUsers}
                                onChange={handleSharedUserChange}
                                renderInput={(params) => <TextField {...params} label="Sharing" />}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        pr: '30px !important'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant="contained" size="small" onClick={handleSharingSettingsSave}>
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

NavMyFilesItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavMyFilesItem;
