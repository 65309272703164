import React from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import NavAllGroup from './NavAllGroup';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconPencil, IconBriefcase, IconFolder, IconSend, IconList, IconQuestionMark } from '@tabler/icons';

// Function to build the array that will be utilized as the list of menu items.
const arrBuilder = (item) => {
    switch (item.itemType) {
        case 'myFilesReport':
            return {
                id: item.id,
                title: <FormattedMessage id={item.title} />,
                type: 'myFilesItem',
                shared: item.shared,
                url: item.url,
                icon: IconPencil
            };
        case 'myFilesSurvey':
            return {
                id: item.id,
                title: <FormattedMessage id={item.title} />,
                type: 'myFilesItem',
                shared: item.shared,
                url: item.url,
                icon: IconQuestionMark
            };
        case 'myFilesList':
            return {
                id: item.id,
                title: <FormattedMessage id={item.title} />,
                type: 'myFilesItem',
                shared: item.shared,
                url: item.url,
                icon: IconList
            };
        case 'myFilesCampaign':
            return {
                id: item.id,
                title: <FormattedMessage id={item.title} />,
                type: 'myFilesItem',
                shared: item.shared,
                url: item.url,
                icon: IconSend
            };
        case 'myFilesFolder':
            return {
                id: item.id,
                title: <FormattedMessage id={item.title} />,
                type: 'myFilesFolder',
                shared: item.shared,
                icon: IconFolder,
                children: item.children.map((child) => arrBuilder(child))
            };
        case 'createNewDashboard':
            return {
                id: item.id,
                title: <FormattedMessage id={item.title} />,
                type: 'createNewItem',
                url: item.url,
                icon: IconBriefcase
            };
        case 'createNew':
            return {
                id: item.id,
                title: <FormattedMessage id={item.title} />,
                type: 'createNew',
                icon: IconPencil,
                children: item.children.map((child) => arrBuilder(child))
            };
        case 'createNewFolder':
            return {
                id: item.id,
                title: <FormattedMessage id={item.title} />,
                type: 'createNewFolder',
                icon: IconFolder
            };
        case 'createNewCampaign':
            return {
                id: item.id,
                title: <FormattedMessage id={item.title} />,
                type: 'createNewItem',
                url: item.url,
                icon: IconSend
            };
        case 'createNewList':
            return {
                id: item.id,
                title: <FormattedMessage id={item.title} />,
                type: 'createNewItem',
                url: item.url,
                icon: IconList
            };
        case 'createNewSurvey':
            return {
                id: item.id,
                title: <FormattedMessage id={item.title} />,
                type: 'createNewItem',
                url: item.url,
                icon: IconQuestionMark
            };
        default:
            return <h6>Menu Items Errorss</h6>;
    }
};

// ===========================|| SIDEBAR MENU LIST ||=========================== //

const MenuList = () => {
    // Load menu items from Redux & Create Array
    const menuItemsPull = useSelector((state) => state.menuItems);
    const createNewChildren = menuItemsPull.createNew.map((item) => arrBuilder(item));
    const myFilesChildren = menuItemsPull.myFiles.map((item) => arrBuilder(item));

    // Add Create New Group to Loaded Arrays of Items
    const createNew = {
        id: 'ui-main',
        title: <FormattedMessage id="main" />,
        type: 'group',
        children: createNewChildren
    };

    // Add MyFiles Group to Loaded Array of Items
    const myFiles = {
        id: 'ui-myFiles',
        title: <FormattedMessage id="My Files" />,
        type: 'group',
        children: myFilesChildren
    };

    // Combine Main Navigation Setup & Group of Loaded Items
    const menuItems = [createNew, myFiles];

    const navItems = menuItems.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavAllGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return navItems;
};

export default MenuList;
