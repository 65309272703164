/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { List } from '@material-ui/core';

// project imports
import Notification from './Notification';

// style constant
const useStyles = makeStyles((theme) => ({
    navContainer: {
        width: '100%',
        maxWidth: '330px',
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '300px'
        }
    }
}));

// ===========================|| NOTIFICATION LIST ITEM ||=========================== //

const NotificationList = () => {
    const classes = useStyles();
    const notifications = useSelector((state) => state.profile.notifications);

    const notificationComponents = notifications.map((notification) => <Notification key={notification.id} notification={notification} />);

    return <List className={classes.navContainer}>{notificationComponents}</List>;
};

export default NotificationList;
