// import UUIDs for ID Management
import { v4 as uuid } from 'uuid';

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    userProfileFirstName: 'Juan',
    userProfileLastName: 'Doe',
    userProfileEmail: 'juan.doe@bidenforamericanandbeyond.com',
    userProfileCity: 'New York',
    userProfileState: 'New York',
    userProfilePhone: '123-456-7890',
    userProfileImage: 'user-round.svg',
    organizationName: 'Biden for America & Beyond',
    notifications: [
        {
            id: uuid(),
            userProfileFirstName: 'John',
            userProfileLastName: 'Doe',
            userProfileImage: 'avatar-1.png',
            notificationTime: '2 min ago',
            message: 'It is a long established fact that a reader will be distracted'
        },
        {
            id: uuid(),
            userProfileFirstName: 'Tane',
            userProfileLastName: 'Doe',
            userProfileImage: 'user-round.svg',
            notificationTime: '5 min ago',
            message: 'regardless of Im not sure but is this working'
        },
        {
            id: uuid(),
            userProfileFirstName: 'Joshua',
            userProfileLastName: 'Doe',
            userProfileImage: 'user-round.svg',
            notificationTime: '5 min ago',
            message: 'regardless of Im not sure but is this working'
        },
        {
            id: uuid(),
            userProfileFirstName: 'Jason',
            userProfileLastName: 'Doe',
            userProfileImage: 'user-round.svg',
            notificationTime: '5 min ago',
            message: 'regardless of Im not sure but is this working'
        },
        {
            id: uuid(),
            userProfileFirstName: 'Jackie',
            userProfileLastName: 'Doe',
            userProfileImage: 'user-round.svg',
            notificationTime: '5 min ago',
            message: 'regardless of Im not sure but is this working'
        },
        {
            id: uuid(),
            userProfileFirstName: 'Jessica',
            userProfileLastName: 'Doe',
            userProfileImage: 'user-round.svg',
            notificationTime: '5 min ago',
            message: 'regardless of Im not sure but is this working'
        }
    ]
};

// ===========================|| MENU ITEMS REDUCER ||=========================== //

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_ALL_NOTIFICATIONS: {
            return {
                ...state,
                notifications: []
            };
        }
        case actionTypes.CLEAR_NOTIFICATION: {
            const { id } = action.payload;
            const newNotifArr = state.notifications.flatMap((item) => {
                let newItem;
                if (item.id === id) {
                    newItem = [];
                }
                if (item.id !== id) {
                    newItem = item;
                }
                return newItem;
            });
            return {
                ...state,
                notifications: newNotifArr
            };
        }
        case actionTypes.CHANGE_FIRST_NAME: {
            const { firstName } = action.payload;
            return {
                ...state,
                userProfileFirstName: firstName
            };
        }
        case actionTypes.CHANGE_LAST_NAME: {
            const { lastName } = action.payload;
            return {
                ...state,
                userProfileLastName: lastName
            };
        }
        case actionTypes.CHANGE_EMAIL: {
            const { email } = action.payload;
            return {
                ...state,
                userProfileEmail: email
            };
        }
        case actionTypes.CHANGE_CITY: {
            const { city } = action.payload;
            return {
                ...state,
                userProfileCity: city
            };
        }
        case actionTypes.CHANGE_STATE: {
            const { usState } = action.payload;
            return {
                ...state,
                userProfileState: usState
            };
        }
        case actionTypes.CHANGE_PHONE: {
            const { phone } = action.payload;
            return {
                ...state,
                userProfilePhone: phone
            };
        }
        default:
            return state;
    }
};

export default profileReducer;
