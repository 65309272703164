import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { makeStyles, withStyles } from '@material-ui/styles';
import {
    Collapse,
    List,
    ListItemIcon,
    ListItemText,
    Typography,
    IconButton,
    Tooltip,
    Dialog,
    Grid,
    TextField,
    Button,
    Autocomplete
} from '@material-ui/core';
import ListItemButton from '@material-ui/core/ListItemButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

// project imports
import NavMyFilesItem from '../NavMyFilesItem';
import { gridSpacing } from 'store/constant';
import { RENAME_FILE, SET_SHARED_USERS, SET_FOLDER_ITEMS } from 'store/actions';

// assets
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { IconChevronDown, IconChevronUp, IconSettings } from '@tabler/icons';
import CloseIcon from '@material-ui/icons/Close';

// style constant
const useStyles = makeStyles((theme) => ({
    collapseIcon: {
        fontSize: '1rem',
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    collapseIconSub: {
        fontSize: '1rem',
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    menuIcon: {
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    listIcon: {
        minWidth: '18px',
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    listCustomIconSub: {
        width: '6px',
        height: '6px'
    },
    listCustomIconSubActive: {
        width: '8px',
        height: '8px'
    },
    listItem: {
        marginBottom: '5px',
        alignItems: 'flex-start'
    },
    listItemNoBack: {
        marginBottom: '5px',
        backgroundColor: 'transparent !important',
        paddingTop: '8px',
        paddingBottom: '8px',
        alignItems: 'flex-start'
    },
    subMenuCaption: {
        ...theme.typography.subMenuCaption
    },
    collapseWrapper: {
        position: 'relative',
        '&:after': {
            content: "''",
            position: 'absolute',
            left: '32px',
            top: 0,
            height: '100%',
            width: '1px',
            opacity: theme.palette.mode === 'dark' ? 0.2 : 1,
            background: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary.light
        }
    }
}));

// style for dialog constant
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2.5)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.grey[500]
    }
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(3)
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    }
}))(MuiDialogActions);

// =============================|| DIALOG TITLE ||============================= //

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle className={classes.root} {...other}>
            {children}
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

// ===========================|| SIDEBAR FOLDER ITEMS ||=========================== //

const NavMyFilesFolder = ({ menu, level }) => {
    const classes = useStyles();
    const customization = useSelector((state) => state.customization);
    const users = useSelector((state) => state.users);
    const dispatch = useDispatch();

    // Handles whether or not folder is open & selected.
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const handleClick = () => {
        setOpen(!open);
        setSelected(!selected ? menu.id : null);
    };

    // Shows settings tooltip based on hover over folder.
    const [active, setActive] = useState(false);
    const showSettings = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setActive(true);
        console.log('Mouse has entered the item.');
    };
    const hideSettings = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setActive(false);
        console.log('Mouse has left the item.');
    };

    // Handles the opening and closing of the dialog.
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleTooltipClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setDialogOpen(true);
    };
    const handleClose = () => {
        setDialogOpen(false);
        setActive(false);
    };

    // Handles Change of the File Name
    const [fileName, setFileName] = useState(menu.title.props.id);
    const handleFileNameChange = (e) => {
        setFileName(e.target.value);
    };

    // Creates array of default users that were shared on the folder to be used by State below.
    const defaultSharedUsers = menu.shared.map((item) => {
        const findUserName = (element) => element.id === item;
        const index = users.findIndex(findUserName);
        return users[index];
    });

    // Handles Change of Shared Users
    const [sharedUsers, setSharedUsers] = useState(defaultSharedUsers);
    const handleSharedUserChange = (e, value) => {
        e.preventDefault();
        setSharedUsers(value);
    };

    // Pulls all files and children from state and sets fileOptions equal to array of all items.
    const files = useSelector((state) => state.menuItems.myFiles);
    const mainAllFilesCreator = (files) => {
        let allFiles = [];
        const allFilesCreator = (item) => {
            if (item.children) {
                allFiles = [...allFiles, ...item.children];
            } else {
                allFiles.push(item);
            }
        };
        files.forEach((item) => allFilesCreator(item));
        return allFiles;
    };
    const allFileOptions = mainAllFilesCreator(files);

    // Creates array of default files in the folder.
    const defaultFileOptions = menu.children.map((item) => {
        const findFileName = (element) => element.id === item.id;
        const index = allFileOptions.findIndex(findFileName);
        return allFileOptions[index];
    });

    // Handles Change of Folder Items
    const [fileOptions, setFileOptions] = useState(defaultFileOptions);
    const handleFileOptionsChange = (e, value) => {
        e.preventDefault();
        setFileOptions(value);
    };

    // handle sharing and settings save for dialog
    const handleSharingSettingsSave = (e) => {
        e.preventDefault();
        dispatch({ type: RENAME_FILE, payload: { id: menu.id, newName: fileName } });
        dispatch({ type: SET_FOLDER_ITEMS, payload: { id: menu.id, fileOptions } });
        dispatch({ type: SET_SHARED_USERS, payload: { id: menu.id, users: sharedUsers } });
        console.log(fileOptions);
        handleClose();
    };

    // menu collapse & item
    const menus = menu.children.map((item) => {
        switch (item.type) {
            case 'myFilesFolder':
                return <NavMyFilesFolder key={item.id} menu={item} level={level + 1} />;
            case 'myFilesItem':
                return <NavMyFilesItem key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = menu.icon;
    const menuIcon = menu.icon ? (
        <Icon stroke={1.5} size="1.3rem" className={classes.listCustomIcon} />
    ) : (
        <FiberManualRecordIcon
            className={selected === menu.id ? classes.listCustomIconSubActive : classes.listCustomIconSub}
            fontSize={level > 0 ? 'inherit' : 'default'}
        />
    );

    const menuIconClass = !menu.icon ? classes.listIcon : classes.menuIcon;

    return (
        <>
            <ListItemButton
                className={level > 1 ? classes.listItemNoBack : classes.listItem}
                sx={{ borderRadius: `${customization.borderRadius}px` }}
                selected={selected === menu.id}
                onClick={handleClick}
                style={{ paddingLeft: `${level * 23}px` }}
                onMouseEnter={(e) => showSettings(e)}
                onMouseLeave={(e) => hideSettings(e)}
            >
                <ListItemIcon className={menuIconClass}>{menuIcon}</ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant={selected === menu.id ? 'h5' : 'body1'} color="inherit" className={classes.listItemTypography}>
                            {menu.title}
                        </Typography>
                    }
                    secondary={
                        menu.caption && (
                            <Typography variant="caption" className={classes.subMenuCaption} display="block" gutterBottom>
                                {menu.caption}
                            </Typography>
                        )
                    }
                />
                {active ? (
                    <Tooltip title="Settings">
                        <IconButton color="primary" size="small" onClick={(e) => handleTooltipClick(e)}>
                            <IconSettings stroke={1.5} size="1.5rem" />
                        </IconButton>
                    </Tooltip>
                ) : null}
                {open ? (
                    <IconChevronUp stroke={1.5} size="1rem" className={level > 1 ? classes.collapseIconSub : classes.collapseIcon} />
                ) : (
                    <IconChevronDown stroke={1.5} size="1rem" className={level > 1 ? classes.collapseIconSub : classes.collapseIcon} />
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.collapseWrapper}>
                    {menus}
                </List>
            </Collapse>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={dialogOpen}
                sx={{ '& .MuiDialog-paper': { pr: 0 } }}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ minWidth: '500px' }}>
                    Sharing and Settings
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={gridSpacing}>
                        <Grid item sm={12}>
                            <TextField fullWidth label="File Name" value={fileName} onChange={handleFileNameChange} />
                        </Grid>
                        <Grid item sm={12}>
                            <Autocomplete
                                multiple
                                options={users}
                                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                defaultValue={sharedUsers}
                                onChange={handleSharedUserChange}
                                renderInput={(params) => <TextField {...params} label="Sharing" />}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        pr: '30px !important'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Autocomplete
                                multiple
                                options={allFileOptions}
                                getOptionLabel={(option) => option.title}
                                defaultValue={fileOptions}
                                onChange={handleFileOptionsChange}
                                renderInput={(params) => <TextField {...params} label="Folder Items" />}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        pr: '30px !important'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant="contained" size="small" onClick={handleSharingSettingsSave}>
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

NavMyFilesFolder.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number
};

export default NavMyFilesFolder;
