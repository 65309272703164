import React, { lazy } from 'react';

// project imports
import VolunteerLayout from 'layout/VolunteerLayout';
import Loadable from 'ui-component/Loadable';

// text banking routing
const VolunteerTextBank = Loadable(lazy(() => import('views/VolunteerTextBank')));

// phone banking routing
const VolunteerPhoneBank = Loadable(lazy(() => import('views/VolunteerPhoneBank')));

// phone banking routing
const VolunteerCanvas = Loadable(lazy(() => import('views/VolunteerCanvas')));

// ===========================|| AUTH ROUTING ||=========================== //

const VolunteerRoutes = {
    path: 'volunteer',
    element: <VolunteerLayout />,
    children: [
        {
            path: '/text-bank',
            element: <VolunteerTextBank />
        },
        {
            path: '/phone-bank',
            element: <VolunteerPhoneBank />
        },
        {
            path: '/canvas',
            element: <VolunteerCanvas />
        }
    ]
};

export default VolunteerRoutes;
