import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { useTheme } from '@material-ui/styles';
import logoDark from 'assets/images/logo-dark.svg';
import logo from 'assets/images/logo.svg';

// ===========================|| LOGO SVG ||=========================== //

const Logo = () => {
    const theme = useTheme();

    return (
        <RouterLink to="/">
            <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="VIZ Builder" width="100" height="50" />
        </RouterLink>
    );
};

export default Logo;
