// import UUIDs for ID Management
import { v4 as uuid } from 'uuid';

// action - state management
import * as actionTypes from './actions';

export const initialState = [
    {
        id: uuid(),
        firstName: 'Tahseen',
        lastName: 'Juan',
        email: 'snowWhafite@gmail.com',
        phone: 'argserg',
        groups: ['group2']
    },
    {
        id: 1,
        firstName: 'Tahseen',
        lastName: 'Chowdhury',
        email: 'snowWhafite@gmail.com',
        phone: 'argserg',
        groups: ['group3']
    },
    {
        id: 2,
        firstName: 'Reevu',
        lastName: 'Islam',
        email: 'snowWhafite@gmail.com',
        phone: 'argserg',
        groups: ['group2', 'group3']
    },
    {
        id: 3,
        firstName: 'Reevu',
        lastName: 'Islam',
        email: 'snowWhafite@gmail.com',
        phone: 'argserg',
        groups: []
    },
    {
        id: 4,
        firstName: 'Reevu',
        lastName: 'Islam',
        email: 'snowWhafite@gmail.com',
        phone: 'argserg',
        groups: []
    },
    {
        id: 5,
        firstName: 'Reevu',
        lastName: 'Islam',
        email: 'snowWhafite@gmail.com',
        phone: 'argserg',
        groups: []
    },
    {
        id: 6,
        firstName: 'Reevu',
        lastName: 'Islam',
        email: 'snowWhafite@gmail.com',
        phone: 'argserg',
        groups: []
    },
    {
        id: 7,
        firstName: 'Reevu',
        lastName: 'Islam',
        email: 'snowWhafite@gmail.com',
        phone: 'argserg',
        groups: []
    }
];

// ===========================|| USER REDUCER ||=========================== //

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REMOVE_USER_FROM_GROUP: {
            const { ids, groups } = action.payload;
            const newState = state.map((user) => {
                if (ids.indexOf(user.id) > -1) {
                    return {
                        ...user,
                        groups: groups.filter((group) => user.groups.indexOf(group) === -1)
                    };
                }
                return user;
            });
            return newState;
        }
        case actionTypes.ADD_USERS_TO_GROUPS: {
            const { ids, groups } = action.payload;
            const newState = state.map((user) => {
                if (ids.indexOf(user.id) !== -1) {
                    return {
                        ...user,
                        groups: [...user.groups, ...groups]
                    };
                }
                return user;
            });
            return newState;
        }
        case actionTypes.ADD_USER: {
            const { firstName, lastName, email, phone, groups } = action.payload;
            const userToAdd = {
                firstName,
                lastName,
                email,
                phone,
                groups,
                id: uuid()
            };
            return [...state, userToAdd];
        }
        case actionTypes.CHANGE_USER_INFO: {
            const { id, updatedUser } = action.payload;
            const newState = state.map((user) => {
                if (user.id === id) {
                    return {
                        ...user,
                        ...updatedUser
                    };
                }
                return user;
            });
            return newState;
        }
        default:
            return state;
    }
};

export default userReducer;
