import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import menuItemsReducer from './menuItemsReducer';
import userReducer from './userReducer';
import profileReducer from './profileReducer';
import volunteerReducer from './volunteerReducer';
import campaignCreationReducer from './campaignCreationReducer';
import listCreationReducer from './listCreationReducer';
import surveyCreationReducer from './surveyCreationReducer';

// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
    customization: customizationReducer,
    menuItems: menuItemsReducer,
    users: userReducer,
    profile: profileReducer,
    volunteers: volunteerReducer,
    campaignCreator: campaignCreationReducer,
    listCreator: listCreationReducer,
    surveyCreator: surveyCreationReducer
});

export default reducer;
