// import UUIDs for ID Management
import { v4 as uuid } from 'uuid';

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    filters: [
        {
            id: uuid(),
            type: 'firstName',
            value: 'John',
            andOr: 'or'
        },
        {
            id: uuid(),
            type: 'middleName',
            value: 'M',
            andOr: 'or'
        },
        {
            id: uuid(),
            type: 'lastName',
            value: 'Doe',
            andOr: 'or'
        },
        {
            id: uuid(),
            type: 'dateOfBirth',
            value: 'People Born From 07/20/2000 Until 11/24/2020',
            andOr: 'or'
        },
        {
            id: uuid(),
            type: 'gender',
            value: 'Male, Female'
        },
        {
            id: uuid(),
            type: 'race',
            value: 'Asian, Black'
        },
        {
            id: uuid(),
            type: 'votedInElections',
            value: '2020 Primary, 2020 General'
        },
        {
            id: uuid(),
            type: 'votedInElections',
            value: '2020 Primary, 2020 General'
        },
        {
            id: uuid(),
            type: 'voterStatus',
            value: 'Active, Inactive'
        },
        {
            id: uuid(),
            type: 'earlyVoterStatus',
            value: 'Voted, Not Voted'
        },
        {
            id: uuid(),
            type: 'registrationDate',
            value: 'People Registered to Vote from 11/20/2020 to 12/20/2020'
        },
        {
            id: uuid(),
            type: 'countries',
            value: 'United States'
        },
        {
            id: uuid(),
            type: 'usStates',
            value: 'New York'
        },
        {
            id: uuid(),
            type: 'cities',
            value: 'New York, NY; Queens, NY'
        },
        {
            id: uuid(),
            type: 'streetName',
            value: 'McIntosh Street'
        },
        {
            id: uuid(),
            type: 'streetNumber',
            value: 'Includes Streets from 94 to 72'
        },
        {
            id: uuid(),
            type: 'houseNumber',
            value: 'Includes Houses from 94 to 72'
        },
        {
            id: uuid(),
            type: 'votersPerAddress',
            value: 'From 2 Voters to 8 Voters'
        },
        {
            id: uuid(),
            type: 'unitsPerAddress',
            value: 'From 2 Units to 8 Units'
        },
        {
            id: uuid(),
            type: 'phone',
            value: 'Cell Phone, Landline'
        },
        {
            id: uuid(),
            type: 'email',
            value: 'Has Email, Does Not Have Email'
        },
        {
            id: uuid(),
            type: 'exclude',
            value: 'No Solicitation Request, Do Not Call List'
        },
        {
            id: uuid(),
            type: 'includeVotersFromList',
            value: 'List 1, List 2'
        },
        {
            id: uuid(),
            type: 'excludeVotersFromList',
            value: 'List 1, List 2'
        },
        {
            id: uuid(),
            type: 'surveyQuestion',
            value: 'Survey 1 | Question: Do you intend to vote for Joe Biden? | Answer: Maybe, No'
        }
    ],
    filterOptions: {
        gender: ['Male', 'Female', 'Non-binary', 'Other'],
        race: ['Asian', 'Black', 'White', 'Hispanic', 'Non-Hispanic'],
        elections: ['2021 Primary', '2007 General'],
        voterStatus: ['Active', 'Inactive', 'Purged'],
        earlyVotingStatus: ['Voted Early', 'Has Not Voted Early', 'Not Sure'],
        countries: ['United States'],
        states: [
            'Alaska',
            'Alabama',
            'Arkansas',
            'American Samoa',
            'Arizona',
            'California',
            'Colorado',
            'Connecticut',
            'District of Columbia',
            'Delaware',
            'Florida',
            'Georgia',
            'Guam',
            'Hawaii',
            'Iowa',
            'Idaho',
            'Illinois',
            'Indiana',
            'Kansas',
            'Kentucky',
            'Louisiana',
            'Massachusetts',
            'Maryland',
            'Maine',
            'Michigan',
            'Minnesota',
            'Missouri',
            'Mississippi',
            'Montana',
            'North Carolina',
            'North Dakota',
            'Nebraska',
            'New Hampshire',
            'New Jersey',
            'New Mexico',
            'Nevada',
            'New York',
            'Ohio',
            'Oklahoma',
            'Oregon',
            'Pennsylvania',
            'Puerto Rico',
            'Rhode Island',
            'South Carolina',
            'South Dakota',
            'Tennessee',
            'Texas',
            'Utah',
            'Virginia',
            'Virgin Islands',
            'Vermont',
            'Washington',
            'Wisconsin',
            'West Virginia',
            'Wyoming'
        ],
        cities: ['New York', 'Los Angeles', 'Miami'],
        phoneInfo: ['Cell Phone', 'Landline', 'No Phone'],
        emailInfo: ['Has Email', 'Does Not Have Email', 'No Email'],
        communicationExclusion: ['No Solicitation Request', 'Do Not Call List'],
        voterLists: ['List 1', 'List 2', 'List 3', 'List 4', 'List 5', 'List 6', 'List 7', 'List 8', 'List 9', 'List 10'],
        surveys: [
            { 'Survey 1': [{ 'Question 1': ['Answer 1', 'Answer 2'] }, { 'Question 2': ['Answer 1', 'Answer 2'] }] },
            { 'Survey 2': [{ 'Question 5': ['Answer 8', 'Answer 16'] }, { 'Question 3': ['Answer 7', 'Answer 5'] }] },
            { 'Survey 3': [{ 'Question 4': ['Answer 12', 'Answer 22'] }, { 'Question 6': ['Answer 13', 'Answer 23'] }] }
        ]
    }
};

// ===========================|| CAMPAIGN CREATOR REDUCER ||=========================== //

const listCreationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CREATE_SURVEY_FILTER: {
            const { selectedSurvey, selectedQuestion, selectedAnswerArr } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'surveyQuestion',
                value: `Survey: ${selectedSurvey} | Question: ${selectedQuestion} | Answers: ${selectedAnswerArr.join(', ')}`,
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_EXCLUDE_VOTERS_FROM_LIST_FILTER: {
            const { excludeVotersFromListArr } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'excludeVotersFromList',
                value: excludeVotersFromListArr.join(', '),
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_INCLUDE_VOTERS_FROM_LIST_FILTER: {
            const { includeVotersFromListArr } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'includeVotersFromList',
                value: includeVotersFromListArr.join(', '),
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_EXCLUDE_FILTER: {
            const { excludeArr } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'exclude',
                value: excludeArr.join(', '),
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_EMAIL_FILTER: {
            const { emailArr } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'email',
                value: emailArr.join(', '),
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_PHONE_FILTER: {
            const { phoneArr } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'phone',
                value: phoneArr.join(', '),
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_UNITS_PER_ADDRESS_FILTER: {
            const { startNum, endNum } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'unitsPerAddress',
                value: `From ${startNum} Units to ${endNum} Units`,
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_VOTERS_PER_ADDRESS_FILTER: {
            const { startNum, endNum } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'votersPerAddress',
                value: `From ${startNum} Voters to ${endNum} Voters`,
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_HOUSE_NUMBER_FILTER: {
            const { startNum, endNum } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'houseNumber',
                value: `Includes Houses from ${startNum} to ${endNum}`,
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_STREET_NUMBER_FILTER: {
            const { startNum, endNum } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'streetNumber',
                value: `Includes Streets from ${startNum} to ${endNum}`,
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_STREET_NAME_FILTER: {
            const { streetName } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'streetName',
                value: streetName,
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_CITY_FILTER: {
            const { cityArr } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'cities',
                value: cityArr.join(', '),
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_USSTATE_FILTER: {
            const { usStateArr } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'usStates',
                value: usStateArr.join(', '),
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_COUNTRY_FILTER: {
            const { countryArr } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'countries',
                value: countryArr.join(', '),
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_REGISTER_DATE_FILTER: {
            const { startDate, endDate } = action.payload;
            const startMonth = startDate.getMonth() + 1;
            const startDay = startDate.getDate();
            const startYear = startDate.getFullYear();
            const endMonth = endDate.getMonth() + 1;
            const endDay = endDate.getDate();
            const endYear = endDate.getFullYear();
            const newFilter = {
                id: uuid(),
                type: 'registrationDate',
                value: `${startMonth}/${startDay}/${startYear} to ${endMonth}/${endDay}/${endYear}`,
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_EARLY_VOTING_STATUS_FILTER: {
            const { statusArr } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'earlyVoterStatus',
                value: statusArr.join(', '),
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_VOTER_STATUS_FILTER: {
            const { statusArr } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'voterStatus',
                value: statusArr.join(', '),
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_VOTER_ELECTION_HISTORY_FILTER: {
            const { electionArr } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'votedInElections',
                value: `${electionArr.join(', ')}`,
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_RACE_FILTER: {
            const { raceArr } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'race',
                value: `${raceArr.join(', ')}`,
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_GENDER_FILTER: {
            const { genderArr } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'gender',
                value: `${genderArr.join(', ')}`,
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_DATE_OF_BIRTH_FILTER: {
            const { startDate, endDate } = action.payload;
            const startMonth = startDate.getMonth() + 1;
            const startDay = startDate.getDate();
            const startYear = startDate.getFullYear();
            const endMonth = endDate.getMonth() + 1;
            const endDay = endDate.getDate();
            const endYear = endDate.getFullYear();
            const newFilter = {
                id: uuid(),
                type: 'dateOfBirth',
                value: `${startMonth}/${startDay}/${startYear} to ${endMonth}/${endDay}/${endYear}`,
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_LAST_NAME_FILTER: {
            const { lastName } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'lastName',
                value: lastName,
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_MIDDLE_NAME_FILTER: {
            const { middleName } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'middleName',
                value: middleName,
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.CREATE_FIRST_NAME_FILTER: {
            const { firstName } = action.payload;
            const newFilter = {
                id: uuid(),
                type: 'firstName',
                value: firstName,
                andOr: 'or'
            };
            return {
                ...state,
                filters: [...state.filters, newFilter]
            };
        }
        case actionTypes.TOGGLE_AND_OR_STATUS: {
            const { id } = action.payload;
            const newFilters = state.filters.map((filter) => {
                if (filter.id === id) {
                    return {
                        ...filter,
                        andOr: filter.andOr === 'and' ? 'or' : 'and'
                    };
                }
                return filter;
            });
            return {
                ...state,
                filters: newFilters
            };
        }
        case actionTypes.REMOVE_LIST_FILTER_ITEM: {
            const { id } = action.payload;
            const newFilters = state.filters.filter((filter) => filter.id !== id);
            return {
                ...state,
                filters: newFilters
            };
        }
        default:
            return state;
    }
};

export default listCreationReducer;
