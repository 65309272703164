import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/Dashboard')));

// create new campaign routing
const CreateNewCampaign = Loadable(lazy(() => import('views/CreateNewCampaign')));

// create new list routing
const CreateNewList = Loadable(lazy(() => import('views/CreateNewList')));

// create new survey routing
const CreateNewSurvey = Loadable(lazy(() => import('views/CreateNewSurvey')));

// survey page routing
const SurveyPage = Loadable(lazy(() => import('views/SurveyPage')));

// list page routing
const ListPage = Loadable(lazy(() => import('views/ListPage')));

// campaign page routing
const CampaignPage = Loadable(lazy(() => import('views/CampaignPage')));

// profile page routing
const ProfilePage = Loadable(lazy(() => import('views/ProfilePage')));

// user management page routing
const UserManagementPage = Loadable(lazy(() => import('views/UserManagementPage')));

// volunteer management page routing
const VolunteerManagement = Loadable(lazy(() => import('views/VolunteerManagement')));

// reporting page routing
const Reporting = Loadable(lazy(() => import('views/Reporting')));

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/create-new-campaign/:id',
            element: <CreateNewCampaign />
        },
        {
            path: '/create-new-list',
            element: <CreateNewList />
        },
        {
            path: '/create-new-survey',
            element: <CreateNewSurvey />
        },
        {
            path: '/survey-page',
            element: <SurveyPage />
        },
        {
            path: '/list-page',
            element: <ListPage />
        },
        {
            path: '/campaign-page',
            element: <CampaignPage />
        },
        {
            path: '/profile-page',
            element: <ProfilePage />
        },
        {
            path: '/user-management-page',
            element: <UserManagementPage />
        },
        {
            path: '/volunteer-management',
            element: <VolunteerManagement />
        },
        {
            path: '/reporting',
            element: <Reporting />
        }
    ]
};

export default MainRoutes;
