import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Chip, Grid } from '@material-ui/core';

// project imports
import { MENU_OPEN, SET_SELECTED_STATE, SET_ACTIVE_STATE, REMOVE_ACTIVE_STATE } from 'store/actions';

// assets
import { IconList } from '@tabler/icons';

// styles
const useStyles = makeStyles(() => ({
    iconNav: {
        height: '16px'
    },
    mainCard: {
        padding: '0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
}));

// ================================|| BREADCRUMB NAVIGATION ||================================ //

const BreadcrumbNavMyFilesList = ({ item, breadcrumbIndex, breadcrumbArray }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const redirectItem = breadcrumbArray[breadcrumbIndex - 1];

    // On Delete the following needs to happen.
    // 1. It sets active to false. ===============================================DONE
    // 2. It sets selected to false. =============================================DONE
    // 3. It sets Redirect Selected to true. =====================================DONE
    // 4. It sets Redirect Active to true. =======================================DONE
    // 5. It switches over to Redirect URL. ======================================DONE
    // 6. It changes the active state to Redirect Item for main menu. ============DONE
    const handleDelete = (e) => {
        e.preventDefault();
        dispatch({ type: REMOVE_ACTIVE_STATE, payload: { id: item.id } });
        if (item.selected) {
            dispatch({ type: MENU_OPEN, id: redirectItem.id });
            dispatch({ type: SET_SELECTED_STATE, payload: { id: redirectItem.id } });
            dispatch({ type: SET_ACTIVE_STATE, payload: { id: redirectItem.id } });
            navigate(redirectItem.url);
        }
    };

    // One Click, the following needs to happen.
    // 1. It sets selected to true and sets all other selecteds to false. =====DONE
    // 2. It needs to switch over to the corresponding URL. ===================DONE
    // 3. It changes the active state for the default menu. ===================DONE
    const itemHandler = (itemId) => {
        dispatch({ type: MENU_OPEN, id: itemId });
        dispatch({ type: SET_SELECTED_STATE, payload: { id: item.id } });
    };
    let listItemProps = { component: React.forwardRef((props, ref) => <Link {...props} to={item.url} ref={ref} />) };
    if (item.external) {
        listItemProps = { component: 'a', href: item.url };
    }

    return (
        <Grid item>
            <Chip
                {...listItemProps}
                icon={<IconList className={classes.iconNav} />}
                label={item.title}
                onClick={() => itemHandler(item.id)}
                onDelete={handleDelete}
                color="primary"
                variant={item.selected ? 'filled' : 'outlined'}
            />
        </Grid>
    );
};

BreadcrumbNavMyFilesList.propTypes = {
    item: PropTypes.object.isRequired,
    breadcrumbIndex: PropTypes.number.isRequired,
    breadcrumbArray: PropTypes.array.isRequired
};

export default BreadcrumbNavMyFilesList;
