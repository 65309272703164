// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';
export const PRESET_COLORS = '@customization/PRESET_COLORS';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_OUTLINED_FILLED = '@customization/SET_OUTLINED_FILLED';

// action - menuItemsReducer
export const ADD_FOLDER = '@menuItems/ADD_FOLDER';
export const RENAME_FILE = '@menuItems/RENAME_FILE';
export const SET_SHARED_USERS = '@menuItems/SET_SHARED_USERS';
export const SET_FOLDER_ITEMS = '@menuItems/SET_FOLDER_ITEMS';
export const SET_SELECTED_STATE = '@menuItems/SET_SELECTED_STATE';
export const SET_ACTIVE_STATE = '@menuItems/SET_ACTIVE_STATE';
export const REMOVE_ACTIVE_STATE = '@menuItems/REMOVE_ACTIVE_STATE';

// action - profileReducer
export const CLEAR_NOTIFICATION = '@profile/CLEAR_NOTIFICATION';
export const CLEAR_ALL_NOTIFICATIONS = '@profile/CLEAR_ALL_NOTIFICATIONS';
export const CHANGE_FIRST_NAME = '@profile/CHANGE_FIRST_NAME';
export const CHANGE_LAST_NAME = '@profile/CHANGE_LAST_NAME';
export const CHANGE_EMAIL = '@profile/CHANGE_EMAIL';
export const CHANGE_CITY = '@profile/CHANGE_CITY';
export const CHANGE_STATE = '@profile/CHANGE_STATE';
export const CHANGE_PHONE = '@profile/CHANGE_PHONE';

// action - volunteerReducer
export const CHANGE_VOLUNTEER_INFO = '@volunteer/CHANGE_EMAIL';
export const ADD_VOLUNTEER = '@volunteer/ADD_VOLUNTEER';
export const ADD_VOLUNTEERS_TO_GROUPS = '@volunteer/ADD_VOLUNTEERS_TO_GROUPS';
export const REMOVE_VOLUNTEER_FROM_GROUP = '@volunteer/REMOVE_VOLUNTEER_FROM_GROUP';

// action - userReducer
export const CHANGE_USER_INFO = '@user/CHANGE_EMAIL';
export const ADD_USER = '@user/ADD_USER';
export const ADD_USERS_TO_GROUPS = '@user/ADD_USERS_TO_GROUPS';
export const REMOVE_USER_FROM_GROUP = '@user/REMOVE_USER_FROM_GROUP';

// action - campaignCreationReducer
export const REMOVE_TURF_ASSIGNMENT = '@campaignCreator/REMOVE_TURF_ASSIGNMENT';
export const ADD_TURF_ASSIGNMENT = '@campaignCreator/ADD_TURF_ASSIGNMENT';
export const SET_TURF_USER_GROUP_ASSIGNMENT = '@campaignCreator/SET_TURF_USER_GROUP_ASSIGNMENT';
export const SET_TURF_ASSIGNMENT = '@campaignCreator/SET_TURF_ASSIGNMENT';

// action - listCreationReducer
export const REMOVE_LIST_FILTER_ITEM = '@listCreator/REMOVE_LIST_FILTER_ITEM';
export const TOGGLE_AND_OR_STATUS = '@listCreator/TOGGLE_AND_OR_STATUS';
export const CREATE_FIRST_NAME_FILTER = '@listCreator/CREATE_FIRST_NAME_FILTER';
export const CREATE_MIDDLE_NAME_FILTER = '@listCreator/CREATE_MIDDLE_NAME_FILTER';
export const CREATE_LAST_NAME_FILTER = '@listCreator/CREATE_LAST_NAME_FILTER';
export const CREATE_DATE_OF_BIRTH_FILTER = '@listCreator/CREATE_DATE_OF_BIRTH_FILTER';
export const CREATE_GENDER_FILTER = '@listCreator/CREATE_GENDER_FILTER';
export const CREATE_RACE_FILTER = '@listCreator/CREATE_RACE_FILTER';
export const CREATE_VOTER_ELECTION_HISTORY_FILTER = '@listCreator/CREATE_VOTER_ELECTION_HISTORY_FILTER';
export const CREATE_VOTER_STATUS_FILTER = '@listCreator/CREATE_VOTER_STATUS_FILTER';
export const CREATE_EARLY_VOTING_STATUS_FILTER = '@listCreator/CREATE_EARLY_VOTING_STATUS_FILTER';
export const CREATE_REGISTER_DATE_FILTER = '@listCreator/CREATE_REGISTER_DATE_FILTER';
export const CREATE_COUNTRY_FILTER = '@listCreator/CREATE_COUNTRY_FILTER';
export const CREATE_USSTATE_FILTER = '@listCreator/CREATE_USSTATE_FILTER';
export const CREATE_CITY_FILTER = '@listCreator/CREATE_CITY_FILTER';
export const CREATE_STREET_NAME_FILTER = '@listCreator/CREATE_STREET_NAME_FILTER';
export const CREATE_STREET_NUMBER_FILTER = '@listCreator/CREATE_STREET_NUMBER_FILTER';
export const CREATE_HOUSE_NUMBER_FILTER = '@listCreator/CREATE_HOUSE_NUMBER_FILTER';
export const CREATE_VOTERS_PER_ADDRESS_FILTER = '@listCreator/CREATE_VOTERS_PER_ADDRESS_FILTER';
export const CREATE_UNITS_PER_ADDRESS_FILTER = '@listCreator/CREATE_UNITS_PER_ADDRESS_FILTER';
export const CREATE_PHONE_FILTER = '@listCreator/CREATE_PHONE_FILTER';
export const CREATE_EMAIL_FILTER = '@listCreator/CREATE_EMAIL_FILTER';
export const CREATE_EXCLUDE_FILTER = '@listCreator/CREATE_EXCLUDE_FILTER';
export const CREATE_INCLUDE_VOTERS_FROM_LIST_FILTER = '@listCreator/CREATE_INCLUDE_VOTERS_FROM_LIST_FILTER';
export const CREATE_EXCLUDE_VOTERS_FROM_LIST_FILTER = '@listCreator/CREATE_EXCLUDE_VOTERS_FROM_LIST_FILTER';
export const CREATE_SURVEY_FILTER = '@listCreator/CREATE_SURVEY_FILTER';

// action - surveyCreationReducer
export const SET_GREETING_BLOCK_TEXT = '@surveyCreator/SET_GREETING_BLOCK_TEXT';
export const SET_START_TEXT_BLOCK_TEXT = '@surveyCreator/SET_START_TEXT_BLOCK_TEXT';
export const REMOVE_STANDARD_BLOCK = '@surveyCreator/REMOVE_STANDARD_BLOCK';
export const ADD_STANDARD_TEXT_BLOCK = '@surveyCreator/ADD_STANDARD_TEXT_BLOCK';
export const SET_STANDARD_TEXT_BLOCK_TEXT = '@surveyCreator/SET_STANDARD_TEXT_BLOCK_TEXT';
export const ADD_QUESTION_BLOCK = '@surveyCreator/ADD_QUESTION_BLOCK';
export const SET_QUESTION_BLOCK_COMMENT_SWITCH = '@surveyCreator/SET_QUESTION_BLOCK_COMMENT_SWITCH';
export const SET_QUESTION_BLOCK_QUESTION_TEXT = '@surveyCreator/ADD_QUESTION_BLOCK_QUESTION_TEXT';
export const ADD_QUESTION_BLOCK_ANSWER = '@surveyCreator/ADD_QUESTION_BLOCK_ANSWER';
export const REMOVE_QUESTION_BLOCK_ANSWER = '@surveyCreator/REMOVE_QUESTION_BLOCK_ANSWER';
export const SET_QUESTION_BLOCK_ANSWER_TEXT = '@surveyCreator/ADD_QUESTION_BLOCK_ANSWER_TEXT';
export const SET_QUESTION_BLOCK_DISABLED = '@surveyCreator/SET_QUESTION_BLOCK_DISABLED';
export const SET_QUESTION_BLOCK_ENABLED = '@surveyCreator/SET_QUESTION_BLOCK_ENABLED';
export const ADD_PATH_BLOCK = '@surveyCreator/ADD_PATH_BLOCK';
export const ADD_PATH_BLOCK_PATH_ASSIGNMENT = '@surveyCreator/ADD_PATH_BLOCK_PATH_ASSIGNMENT';
export const REMOVE_PATH_BLOCK_PATH_ASSIGNMENT = '@surveyCreator/REMOVE_PATH_BLOCK_PATH_ASSIGNMENT';
export const SET_PATH_BLOCK_PATH_ASSIGNMENT_START_ITEM = '@surveyCreator/SET_PATH_BLOCK_PATH_ASSIGNMENT_START_ITEM';
export const SET_PATH_BLOCK_PATH_ASSIGNMENT_NAME = '@surveyCreator/SET_PATH_BLOCK_PATH_ASSIGNMENT_NAME';
export const SET_PATH_BLOCK_PATH_ASSIGNMENT_ANSWER_SELECTION = '@surveyCreator/SET_PATH_BLOCK_PATH_ASSIGNMENT_ANSWER_SELECTION';
export const SET_PATH_BLOCK_PATH_GENERATION = '@surveyCreator/SET_PATH_BLOCK_PATH_GENERATION';
export const REMOVE_PATH = '@surveyCreator/REMOVE_PATH';
