/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

// material-ui
import { makeStyles } from '@material-ui/styles';
import {
    Avatar,
    Divider,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    IconButton
} from '@material-ui/core';

// assets
import { IconTrash } from '@tabler/icons';

// project imports
import { CLEAR_NOTIFICATION } from 'store/actions';

// style constant
const useStyles = makeStyles((theme) => ({
    listAction: {
        top: '22px'
    },
    actionColor: {
        color: theme.palette.grey[500]
    },
    listItem: {
        padding: 0
    },
    listDivider: {
        marginTop: 0,
        marginBottom: 0
    },
    listContainer: {
        paddingLeft: '56px'
    },
    paddingBottom: {
        paddingBottom: '16px'
    },
    itemAction: {
        cursor: 'pointer',
        padding: '16px',
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
        }
    }
}));

// ===========================|| NOTIFICATION LIST ITEM ||=========================== //

const Notification = ({ notification }) => {
    const classes = useStyles();
    const { id, userProfileFirstName, userProfileLastName, userProfileImage, notificationTime, message } = notification;
    const dispatch = useDispatch();
    const image = require(`assets/images/users/${userProfileImage}`);

    const handleNotificationClear = (e) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch({ type: CLEAR_NOTIFICATION, payload: { id } });
    };

    return (
        <>
            <div className={classes.itemAction}>
                <ListItem alignItems="center" className={classes.listItem}>
                    <ListItemAvatar>
                        <Avatar alt={`${userProfileFirstName} ${userProfileLastName}`} src={image.default} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography variant="subtitle1">{`${userProfileFirstName} ${userProfileLastName}`}</Typography>}
                    />
                    <ListItemSecondaryAction className={classes.listAction}>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="caption" display="block" gutterBottom className={classes.actionColor}>
                                    {`${notificationTime}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="row" className={classes.listContainer}>
                    <Grid item xs={10} className={classes.paddingBottom}>
                        <Typography variant="subtitle2">{`${message}`}</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.paddingBottom}>
                        <IconButton aria-label="delete" onClick={(e) => handleNotificationClear(e)}>
                            <IconTrash />
                        </IconButton>
                    </Grid>
                </Grid>
            </div>
            <Divider className={classes.listDivider} />
        </>
    );
};

Notification.propTypes = {
    notification: PropTypes.shape({
        id: PropTypes.number,
        message: PropTypes.string,
        notificationTime: PropTypes.string,
        userProfileImage: PropTypes.string,
        userProfileFirstName: PropTypes.string,
        userProfileLastName: PropTypes.string
    })
};

export default Notification;
