// import UUIDs for ID Management
import { v4 as uuid } from 'uuid';

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    turfAssignments: [
        {
            id: uuid()
        },
        {
            id: uuid()
        }
    ]
};

// ===========================|| CAMPAIGN CREATOR REDUCER ||=========================== //

const campaignCreationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TURF_USER_GROUP_ASSIGNMENT: {
            const { id, users } = action.payload;
            const newTurfAssignmentArr = state.turfAssignments.map((turfAssignment) => {
                if (turfAssignment.id === id) {
                    turfAssignment.users = users;
                }
                return turfAssignment;
            });
            return {
                ...state,
                turfAssignments: newTurfAssignmentArr
            };
        }
        case actionTypes.SET_TURF_ASSIGNMENT: {
            const { id, turf } = action.payload;
            const newTurfAssignmentArr = state.turfAssignments.map((turfAssignment) => {
                if (turfAssignment.id === id) {
                    turfAssignment.turf = turf;
                }
                return turfAssignment;
            });
            return {
                ...state,
                turfAssignments: newTurfAssignmentArr
            };
        }
        case actionTypes.ADD_TURF_ASSIGNMENT: {
            return {
                ...state,
                turfAssignments: [...state.turfAssignments, { id: uuid() }]
            };
        }
        case actionTypes.REMOVE_TURF_ASSIGNMENT: {
            const { id } = action.payload;
            const newTurfAssignmentArr = state.turfAssignments.filter((turfAssignment) => turfAssignment.id !== id);
            return {
                ...state,
                turfAssignments: newTurfAssignmentArr
            };
        }
        default:
            return state;
    }
};

export default campaignCreationReducer;
