// import UUIDs for ID Management
import { v4 as uuid } from 'uuid';

// action - state management
import * as actionTypes from './actions';

export const initialState = [
    {
        id: uuid(),
        firstName: 'Snow',
        lastName: 'White',
        email: 'snowWhafite@gmail.com',
        phone: '123-456-7890',
        groups: []
    },
    {
        id: uuid(),
        firstName: 'Snowe',
        lastName: 'Whitfe',
        email: 'snoawefwWhite@gmail.com',
        phone: '123-456-7890',
        groups: ['group3', 'group4', 'group5']
    },
    {
        id: uuid(),
        firstName: 'Snoww',
        lastName: 'White',
        email: 'snowWefwahite@gmail.com',
        phone: '123-456-7890',
        groups: ['group1', 'group2', 'group3', 'group5']
    },
    {
        id: uuid(),
        firstName: 'Snowf',
        lastName: 'Whiste',
        email: 'snowWhafwite@gmail.com',
        phone: '123-456-7890',
        groups: ['group1', 'group2', 'group3', 'group4', 'group5']
    },
    {
        id: uuid(),
        firstName: 'Snowsfg',
        lastName: 'White',
        email: 'snowWawfhite@gmail.com',
        phone: '123-456-7890',
        groups: ['group1', 'group2', 'group4', 'group5']
    }
];

// ===========================|| MENU ITEMS REDUCER ||=========================== //

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REMOVE_VOLUNTEER_FROM_GROUP: {
            const { ids, groups } = action.payload;
            const newState = state.map((volunteer) => {
                if (ids.indexOf(volunteer.id) > -1) {
                    return {
                        ...volunteer,
                        groups: groups.filter((group) => volunteer.groups.indexOf(group) === -1)
                    };
                }
                return volunteer;
            });
            return newState;
        }
        case actionTypes.ADD_VOLUNTEERS_TO_GROUPS: {
            const { ids, groups } = action.payload;
            const newState = state.map((volunteer) => {
                if (ids.indexOf(volunteer.id) !== -1) {
                    return {
                        ...volunteer,
                        groups: [...volunteer.groups, ...groups]
                    };
                }
                return volunteer;
            });
            return newState;
        }
        case actionTypes.ADD_VOLUNTEER: {
            const { firstName, lastName, email, phone, groups } = action.payload;
            const volunteerToAdd = {
                firstName,
                lastName,
                email,
                phone,
                groups,
                id: uuid()
            };
            return [...state, volunteerToAdd];
        }
        case actionTypes.CHANGE_VOLUNTEER_INFO: {
            const { id, updatedVolunteer } = action.payload;
            const newState = state.map((volunteer) => {
                if (volunteer.id === id) {
                    return {
                        ...volunteer,
                        ...updatedVolunteer
                    };
                }
                return volunteer;
            });
            return newState;
        }
        default:
            return state;
    }
};

export default profileReducer;
