import React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// auth provider
import { FirebaseProvider } from 'contexts/FirebaseContext';
// import {JWTProvider} from 'contexts/JWTContext';
// import {Auth0Provider} from 'contexts/Auth0Context';

// intl error correction
import { IntlProvider } from 'react-intl';

// ===========================|| APP ||=========================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <IntlProvider locale="en">
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <FirebaseProvider>
                            <Routes />
                        </FirebaseProvider>
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </IntlProvider>
    );
};

export default App;
